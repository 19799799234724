import { graphql } from "gatsby"
import { ContactUsPage } from "@pageTemplates"

export default ContactUsPage

export const pageQuery = graphql`
  query ContactUs {
    contactUsJson {
      hero {
        title
        image {
          src {
            childImageSharp {
              gatsbyImageData(width: 2000)
            }
            publicURL
          }
          alt
        }
      }
      getInTouch {
        title
        description
        note
        warning
      }
      sendButton
      fields {
        slug
      }
    }
  }
`
